<!-- Finder de Productos -->

<template>
  <div class="productosF" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader">
          </baseHeader>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:850px">

          <!-- Filtro de Búsqueda -->
          <div>
            <div style="display:flex">
              <uiText
                style="width:60%"
                v-model="ct.name[2]"
                :label="ct.name[1]"
                @Enter="eventFiltro('buscar')">
              </uiText>

              <v-select
                style="width:30%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-left: 5px;"
                v-bind="$select"
                v-model="ct.clave[2]"                  
                :items="claves"
                :label="ct.clave[1]"
                item-value="id"
                item-text="clave"                
                @change="eventFiltro('buscar')">
              </v-select>

              <v-btn style="margin-left:5px" v-bind="$cfe.btn.busca" @click="eventFiltro('buscar')">
                <v-icon dark>{{ $cfe.btn.busca.icono }}</v-icon>
              </v-btn>
            </div>
          </div>

          <!-- Grid -->
          <baseGridMD
            style="margin-top:10px"
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"
            :expansible="isReadOnly"
            dense
            height="480px"
            @onEvent="execAccion">

              <!-- Slot botonera Top -->
              <template v-slot:gridTop="{}">
                <div>

                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>

                </div>
              </template>

              <!-- Slot panel expansible -->
              <template v-slot:expansibleMX="{}">
                <productosMX
                  sync
                  syncUpdate
                  :syncAccion="syncAccionSend"
                  :storeRaiz="storeName"
                  :masterStore="storeName"
                  :readOnly="readOnly"
                  :viewMtoOnly="viewMtoOnly">
                </productosMX>
              </template>
          </baseGridMD>
        </div>
      </v-sheet>
    </div>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import productosMX from '@/components/productosMX';

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, productosMX },
    props: {},

    data() {
      return {
        stIni: {
          api: "productosF",
          titulo:"·Productos",
          name:"productosF",
          mView:'productosMX',
          pView:[],
          expansibleMX:'productosMX'
        },

        claves:[]
      };
    },

    

    methods: {

      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        this.headers = [{
          name:[],
          fields:[
            { text: "Nombre", value: "name", width: "100%" }
            
          ]}
        ];

        this.claves= JSON.parse(JSON.stringify(this.$store.state.datos_iniciales.productoclaves));
        this.claves.unshift({id:'', clave:'Todas'})

        this.headerGrid= this.headerSet();
        this.autoLoad= 'buscar';
      },


    },


    computed: {
        isReadOnly() {
          if (!this.readOnly) return `${this.storeName}${this.stIni.mView}`;
          return '';
        }
      }
  };
</script>
